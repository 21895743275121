
const philosophy = [
    {
        title:"Basic Writings Of Chuangtzu",
        author:"Chuangtzu",
        image:"./books/basicWritings.jpg",
        link:<a href='./files/Chuang-Tzu-Basic-Writings.pdf' className="Book-link" download>Download</a>
    },
    {
        title:"Daily Stoic",
        author:"Ryan Holiday",
        image:"./books/dailyStoic.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Daily-Stoic-Meditations-Wisdom-Perseverance/dp/0735211736/ref=sr_1_1?crid=6A53DK72NU2K&dib=eyJ2IjoiMSJ9.IYfaaHZmpIvd44hnBrgYI3m4z4-RSAc0NCAveoWetDSLledgsieA-TN5dGl_xLQe-iy45ZMBi9q_M5pFZwmNh0xdwO5HIMurrjp6_FmbzpblQNTIPChZ75iSmskAoUj3iyow_PsGG5T7OHfw3N3b8Ft7pLKzgx04ssRMdlkDQrSM1uIsxiqAYH_-kZceV_n3EQ9NkkvYYm0TBpfO-w76mhe_w7YQxubJMHUTpPf0PUI.fcLy-QRcAe6d5Bxna1gnfIbMO2p0HZp6d2lRQ3JY9b0&dib_tag=se&keywords=Daily+Stoic+by+Ryan+Holiday&qid=1737595394&sprefix=daily+stoic+by+ryan+holiday%2Caps%2C260&sr=8-1">Amazon</a>
    },
    {
        title:"Enchiridion",
        author:"Epictetus",
        image:"./books/enchiridion.jpg",
        link:<a className="Book-link" href="./files/Enchiridion-Epictetus.pdf" download>Download</a>
    },
    {
        title:"How To Do Nothing",
        author:"Jenny Odell",
        image:"./books/howToDoNothing.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/How-Do-Nothing-Resisting-Attention/dp/1612198554/ref=sr_1_1?crid=2OBDXH2NUSUAB&dib=eyJ2IjoiMSJ9.pIvExgq05O75l4p0DQ7xwFdWxN-9i2F9ujQ9H9-dJdNVUd6Y5LW8j_sJLYcF0x6I4fm43MHEsTtZtK8v7SCXudD_-PvdpXuoczSjbiJxJwFLXGah_6sBrrDYiN0hbEL6l0C2_Q2jJvnyIzvzyZ9swY94nQKtzkfNiv1DHTwldu1Dan-Udx4kR9gJ4_-VMIw7tZ-COWjEpX_1-axmcVuaIpI21Q02QZ00ekpjJm_S_4c.nxrMVkj_5p4by6hdvN76vK2FTgTrZeFqrtXvcDRykCY&dib_tag=se&keywords=how+to+do+nothing+by+jenny+odell&qid=1737595590&sprefix=how+to+do+nothing+by+jenny+odell%2Caps%2C164&sr=8-1">Amazon</a>
    },
    {
        title:"Letters From A Stoic",
        author:"Seneca",
        image:"./books/lettersFromAStoic.jpg",
        link:<a className="Book-link" href="./files/Letters-From-A-Stoic.pdf" download>Download</a>
    },
    {
        title:"Man's Search For Meaning",
        author:"Viktor Frankl",
        image:"./books/mansSearch.jpg",
        link:<a className="Book-link" href="./files/Man's-Search-For-Meaning.pdf" download>Download</a>
    },
    {
        title:"Meditations",
        author:"Marcus Aurelius",
        image:"./books/meditations.jpg",
        link:<a className="Book-link" href="./files/Meditations-Marcus-Aurelius.pdf" download>Download</a>
    },
    {
        title:"Sophie's World",
        author:"Jostein Gaarder",
        image:"./books/sophiesWorld.jpg",
        link:<a className="Book-link" href="./files/Sophie's-World.pdf" download>Download</a>
    },
    {
        title:"The Courage To Be Disliked",
        author:"Ichiro Kishimi & Fumitake Koga",
        image:"./books/courageToBeDisliked.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Courage-Be-Disliked-Phenomenon-Happiness/dp/1668065967/ref=sr_1_1?crid=327L0IIYXGE2&dib=eyJ2IjoiMSJ9.ObHBfAt6pg5qiieMLFbKtbI3KXdKrKK4ryrzHgFC0io.qc2Xum8HnEo31trNxkHE_r4tdBkS3HZCt9SfR0jATYc&dib_tag=se&keywords=The+Courage+to+be+Disliked+by+Ichiro+Kashimi+and+Fumitake+Koga&qid=1737595699&sprefix=the+courage+to+be+disliked+by+ichiro+kashimi+and+fumitake+koga%2Caps%2C106&sr=8-1">Amazon</a>
    },
    {
        title:"The Four Agreements",
        author:"Don Miguel Ruiz",
        image:"./books/fourAgreements.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Four-Agreements-Practical-Personal-Freedom/dp/1878424319/ref=sr_1_1?crid=RS72DGUIQ88P&dib=eyJ2IjoiMSJ9.L-x8uSbCENVCVyajat5Epdf_baHhptJi54q-4xh0fxra956sIgd_LLjKm39AqLrT_t2AVF_8k9chFyuTovmP9emogwYsNKiPEI7V6cvm8ET-2verm85qQyClNebFmEW5QlN4dMitC6ezOYTX7EDgFh6tDP70KDk_KRW8e12uu3myPX4wof7X4w-VhnPhN8KqR-SILcXn6Dn2_uXvpoSfrlncHxZjtdYNTU9rPfabjv4.EAY0vgkL3pV8zRqvLy7V-1mughoZWod-SntkaOLxdNA&dib_tag=se&keywords=The+Four+Agreements+by+Don+Miguel+Ruiz&qid=1737595301&sprefix=the+four+agreements+by+don+miguel+ruiz%2Caps%2C323&sr=8-1">Amazon</a>
    },
    {
        title:"The Tao Te Ching",
        author:"Laotzu",
        image:"./books/taoTeChing.jpg",
        link:<a className="Book-link" href="./files/Tao-te-Ching.pdf" download>Download</a>
    },
    {
        title:"The Tao Of Pooh",
        author:"Benjamin Hoff",
        image:"./books/taoOfPooh.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Ernest-Shepard-illustrator-Milne-Benjamin/dp/1405293780/ref=sr_1_1?crid=21L2N7OD9CN2N&dib=eyJ2IjoiMSJ9.Ozr-WqEX3ib2W5oZbRZBGf_wYXyRN-veoIaqBeF7QmSV4Qu7tiV81ljvuutP9H4HHTJWCpJAapusA5MOzrYzz11y0fK_sHrY5RZI-HrcVraLhMWD51MiLqmtLaSCvERfopaLyWag41EEdS0H9QWQf9YdLNxoMY_S4-_3e0CcZyUQAmPsjWYS3LHJD2aA-c9Z3rxpK2Ou7kR9HphJPTm2IBCmfhb9nSVj7zfcuS_f_LM.xrvC_C0DAqSg0qiMqDUq0-p013xl2YBcpsyvAFoSx7E&dib_tag=se&keywords=the+tao+of+pooh&qid=1737595561&sprefix=the+tao+of+poo%2Caps%2C196&sr=8-1">Amazon</a>
    },
    {
        title:"The Way Of Zen",
        author:"Alan Watts",
        image:"./books/wayOfZen.jpg",
        link:<a className="Book-link" href="./files/The-Way-Of-Zen.pdf" download>Download</a>
    }
]

const jediRealism =[
    {
        title:"Jediism Explained",
        author:"Opie Macleod",
        image:"./books/jediismExplained.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Jediism-Explained-Opie-Macleod-ebook/dp/B07G1F1K6T/ref=sr_1_1?crid=29GZY9XBDOOPL&dib=eyJ2IjoiMSJ9.KMlxrHz-1SNsPM6tg8qgYNWHEW5qaa68ccvDX4Ggb0E.J8VNYjE3FqpZ8iVFiw0-1MRJxA4bfWlrzMC06IuFW_Y&dib_tag=se&keywords=Opie+Macleod+jediism+explained&qid=1737576601&s=digital-text&sprefix=opie+macleod+jediism+explained%2Cdigital-text%2C96&sr=1-1">Amazon</a>
    },
    {
       title:"The Great Jedi Holocron",
       author:"Various",
       image:"./books/greatJediHolocron.jpg",
       link:<a className="Book-link" href="./files/The-Great-Jedi-Holocron.pdf" download>Download</a> 
    },
    {
        title:"The Jedi Compass",
        author:"Various",
        image:"./books/jediCompass.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Jedi-Compass-Community-ebook/dp/B00ZMOHUQG">Amazon</a>
    },
    {
        title:"The Jedi Circle",
        author:"Opie Macleod",
        image:"./books/jediCircle.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Jedi-Circle-Philosophy-Everyday-Presents/dp/1482637421/ref=sr_1_1?crid=1962DFTIU2XEX&dib=eyJ2IjoiMSJ9.a6fs9jH1YJpCtApYSazJlM1Ckkd7MDp-V2-PKLt7zvApb32jy6OXrEEtQHTNkBUDy5HebM92SYIeKQ7pA29y4w.KnwwVl9W1Z1QoJMYN-mx6LRSUtlapoqZb5vyjbLVabA&dib_tag=se&keywords=Opie+Macleod+jedi+circle&qid=1737576565&s=digital-text&sprefix=opie+macleod+jedi+circl%2Cdigital-text%2C115&sr=1-1-catcorr">Amazon</a>
    },
    {
        title:"The Situational Jedi",
        author:"Opie Macleod",
        image:"./books/situationalJedi.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Situational-Jedi-Jediism-Guide-Life-ebook/dp/B086YLP24W/ref=sr_1_2?crid=1WGQDYPMHP53R&dib=eyJ2IjoiMSJ9.VMuJJIIA7LsKULQ8CCJ4dMEbfVYbkRLvsr0J9NIDqdCo1SbD9rJtYKMoXGE7sR8glrEWVTp1eeMYKXj9Vt-PYj_dljwkqtnTe9-JLhm9-uIHOCm1FDMm44sLjM-nagCrpujcDoF5dsB6ZhWhTyccUNOatTlIxzk4-ipNvbqdCfHtqwcGXQf2rMmoffNIF-Cu1RNUSwvYXTCAaZcaCp1vF1cMKL9znX6OazbBZRBLX6i9eTfGWvl91mx_3P-JIu8P0UffmvQEkGv2CbUTlLlMS8aMePTvVA3bQu5wYEkgSmw.l-MtrCmrzETs6RGLRDrNqjNzD5aC8T5LNYJUggGMRzc&dib_tag=se&keywords=Opie+Macleod&qid=1737576500&s=digital-text&sprefix=opie+macleod%2Cdigital-text%2C177&sr=1-2">Amazon</a>
    },
    {
        title:"Your First Step Into A Larger World",
        author:"L. Christopher Bird",
        image:"./books/firstStep.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Your-First-Step-Larger-World-ebook/dp/B07HCSC1M1/ref=sr_1_1?crid=1KSWHM1A8QTII&dib=eyJ2IjoiMSJ9.qL_PXiomvWgHZRD5WHaPbzowi035nJnx2zGgj7dskL5fcSjrW2Prf0olsLbUXQPANmw24x_nkDVgVr8HXgM5cmdxehubBFwWR0LtbXC3Jwun2gNvQnpzme_CqxZQE7edvoYvyOLe9Vl4BzYtS2ghmjx4UvZ4XBSUSuy5fdyOJoACeEb7E7nD7Vaah_2MmBVGoc1pymq56DilG2XaTyvCqeKHTSsSth3jOGLDGIGfJmDS9DlF_32AkXXihBfdJ_cF.hPL-Qt-dr_XLLYD9nnCI5mPxfDezryy55RkA7zgzdps&dib_tag=se&keywords=your+first+step+into+a+larger+world&qid=1737576626&s=digital-text&sprefix=your+first+step+into+a+larger+world%2Cdigital-text%2C125&sr=1-1">Amazon</a>
    }
]

const diplomaticArts =[
    {
        title:"How To Win Every Argument",
        author:"Madsen Pirie",
        image:"./books/winEveryArgument.jpg",
        link:<a className="Book-link" href="./files/How-To-Win-Every-Argument.pdf" download>Download</a>
    },
    {
        title:"Influence: The Psychology of Persuasion",
        author:"Robert B. Cialdini",
        image:"./books/influence.jpg",
        link:<a className="Book-link" href="./files/Influence-The-Psychology-Of-Persuasion.pdf" download>Download</a>
    },
    {
        title:"The Skilled Helper",
        author:"Gerard Egan",
        image:"./books/theSkilledHelper.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Skilled-Helper-Problem-Management-Opportunity-Development-Approach/dp/9353505909/ref=sr_1_1?crid=1R7W14W8ZQCSF&dib=eyJ2IjoiMSJ9.vk_1dGwB8KV01g7Dt-ZGI_LjRE_AFgX7dLh38rgHa1ioG5JGqFUwydIvjS-DssjECPaFct1XOlA4-yyBTtROlFNrMSjzzTOyoeO7o6E00-WA0nzM-gP28DOS-RNlhI3pOTkC69gN-zijvPnVk8wpS2kKcyZTpO9WuzMd5Hug0xx-pWG7ZHjcjbaFJtCcy0qzH_TLeOBFDqJTJ64kvYiij6rR3xTtDzXM1A_8RDUaF1A.B9XrulFyHNfb9Qo9R2Wa4y-FgTNxRq1uVwT7dUciGD4&dib_tag=se&keywords=The+Skilled+Helper+by+Gerard+Egan&qid=1737595943&sprefix=the+skilled+helper+by+gerard+egan%2Caps%2C202&sr=8-1">Amazon</a>
    },
    {
        title:"Verbal Judo",
        author:"George Thompson",
        image:"./books/verbalJudo.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Verbal-Judo-Gentle-Persuasion-Updated/dp/0062107704/ref=sr_1_1?crid=3CL4MGIK9NCM6&dib=eyJ2IjoiMSJ9.MKVedZe_PesCk1C4b4lmKjxezaazbB97-HNgiVOuCjz30emRyWrbmEpV1WzchcXGjZT04_jPvyQBa8n1pE8qfWXODBDS_uCrJIJEyHqY53VaO2LdFpIDf6XGeHk0T2Wl3b0w7E4w5bxhsoC9yBLQ_c8Rk1ulfsfNuQxmA4qPTYskHkNknseCmfrqRj6llrOR2sitgINc4N7e4YxfGHQ8gQ.U5w4F7uX1gcEn-xbL8CUagG0CnFNClt7sd5sqzrpFxU&dib_tag=se&keywords=Verbal+Judo+by+George+Thompson&qid=1737595875&sprefix=verbal+judo+by+george+thompson%2Caps%2C295&sr=8-1">Amazon</a>
    }
]

const warriorship =[
    {
        title:"Grit",
        author:"Angela Duckworth",
        image:"./books/grit.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Grit-Passion-Perseverance-Angela-Duckworth/dp/1501111116/ref=sr_1_1?crid=PHUYWKSGHHEZ&dib=eyJ2IjoiMSJ9.vQr3htw3swgUz9a-4yhEfG8Mvi6DNxFvQAZXnGrZqgaXv9JRp0Yn24LUXWriSjhoMbNHexFCpR4yEtZvriAhWvlZ9KIJcHvcscsi71EmjScmpenfeXtOI8kXbaz6n7-5hmlTQ5pbSXl8FBWOUwM9qM-zTenXDXutZ6kRfflP3iygnmvotGhISF4CKk7Xd-zLIdALmecoHeBz3b7rwtaZ4GcdoYm9QYi4xWNlTU5v0MQ.8eUKM5FBvLs4SvFM8C_9NYDuXl9ipEivPQFOWS0KWd8&dib_tag=se&keywords=Grit+by+Angela+Duckworth&qid=1737595814&sprefix=grit+by+angela+duckworth%2Caps%2C189&sr=8-1">Amazon</a>
    },
    {
        title:"Just 2 Seconds",
        author:"Gavin de Becker",
        image:"./books/twoSeconds.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Just-2-Seconds-Gavin-Becker/dp/0615214479/ref=sr_1_1?crid=2RFZLLVF8TDYU&dib=eyJ2IjoiMSJ9.CiHQSGXn3W0tvb0CRss9OBMNnSU30Dgm57y_s02rXGE7p0T1jAFkvAmSQZ1w7DEd.8EUvWHV3Nym3oQX8u8p1l6ImTovnnZ9Txa_Takbh1lA&dib_tag=se&keywords=Just+2+Seconds+by+Gavin+de+Becker&qid=1737595745&sprefix=just+2+seconds+by+gavin+de+becker%2Caps%2C327&sr=8-1">Amazon</a>
    },
    {
        title:"Living The Martial Way",
        author:"Forrest E. Morgan",
        image:"./books/livingTheMartialWay.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Living-Martial-Way-Manual-Warrior/dp/0942637763/ref=sr_1_1?crid=Y12TYEIB88D0&dib=eyJ2IjoiMSJ9.vAt5aFodCSp9ysJY26Xxfw.5d6itTbavv8bOuaIRFWR_B_gTh4UTmKY9wEsU2-c2BI&dib_tag=se&keywords=Living+the+Martial+Way+by+Forrest+E.+Morgan&qid=1737595788&sprefix=living+the+martial+way+by+forrest+e.+morgan%2Caps%2C145&sr=8-1">Amazon</a>
    },
    {
        title:"Martial Arts America",
        author:"Bob Orlando",
        image:"./books/martialArtsAmerica.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Martial-Arts-America-Western-Approach/dp/1883319676/ref=sr_1_1?crid=2HSZ6EKQW25UY&dib=eyJ2IjoiMSJ9.VwB_J6E6bmJ25HOwHqwAA4OoPwZhjnIJaURGNa_1s8CLt2S3yCKjU2k-yQ2esP11wDxvriZPST_iu90o99rLV1LfnayaAxOHykfdlC2TJBS-4YnfcGSiBFPP_Q8UQ2kWpi_b2YwZJjCjKATTEz5ukgL7gmyMTTLZtZ4Tm0-P9REUKMQPS19hhcGvTGB5kqB6-gQ7ZPQhGOOKbR57zLPGkDNbfGCPy14uavHLp1bGfMA.d7CqHk8pyLStS-A5IUK6XEiB7OjBSqhjZsE4BKI9CAs&dib_tag=se&keywords=Martial+Arts+America+by+Bob+Orlando&qid=1737595766&sprefix=martial+arts+america+by+bob+orlando%2Caps%2C147&sr=8-1">Amazon</a>
    },
    {
        title:"The Art Of War",
        author:"Sun Tzu",
        image:"./books/artOfWar.jpg",
        link:<a className="Book-link" href="./files/The-Art-Of-War.pdf" download>Download</a>
    },
    {
        title:"The Book Of Five Rings",
        author:"Miyamoto Musashi",
        image:"./books/bookOfFiveRings.jpg",
        link:<a className="Book-link" href="./files/The-Book-Of-Five-Rings.pdf" download>Download</a>
    },
    {
        title:"The Gift Of Fear",
        author:"Gavin de Becker",
        image:"./books/giftOfFear.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Gift-Fear-Gavin-Becker/dp/0316235776/ref=sr_1_1?crid=D4LA8GVMTPZY&dib=eyJ2IjoiMSJ9.mz1clh7PtuSN8VI_0HeCaccFkWsl-xq_UcECbRVzLY-uLdp2t1ieUYVCO9wXNU4CNwcPyaqHVqHw-YtwpddFatB9IZSICjMrukUOC19DOfz7TbIQ66dH-KAJnsmN6Tmq5m9uzyN9tu7BuD_e_oxzcLyEnGA8ulzNIkQGDK2bX5XJ92Pz9u-RBKAGRIqptzl56lkU_jpY75f-SHiiMkgf2XN7sqreJy2YWplB5c1zexw.pwAvUbFR-M4ObY_9PJRti7xbahqK_XgbvPGOjEJhNQs&dib_tag=se&keywords=The+Gift+of+Fear+by+Gavin+de+Becker&qid=1737595726&sprefix=the+gift+of+fear+by+gavin+de+becker%2Caps%2C111&sr=8-1">Amazon</a>
    }
]

const loreAndMyth =[
    {
        title:"I, Jedi",
        author:"Michael A. Stackpole",
        image:"./books/iJedi.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Jedi-Star-Wars-Legends-ebook/dp/B00513D3QE/ref=sr_1_1?crid=2041KGAPC0CWT&dib=eyJ2IjoiMSJ9.zQFVR5QFrVAbpwWuwyQauXiN3w49RWZfak2uqDEf93vU4I9sKQPmOB1mukUhuC2ElXNl0xcQr2LA3SCf0gPe98IkairJnxFLTp2Erth8ldTZT-hx_VtwEqAFjj5Jv_vpZkCUd3bI7XY6pNxxWVcf10cuqu_gwcFhFJJlgsmHpc3LCrZ3-JVZYE-4f9LcL3qjb2j_gau7X6eK19KRr2bJ9xUHLVFXPr2XWGiNJ7JSKyc.XY4sk-0Rz-0RLYPK_y9BD2G_wc5Ev_3iBYIyKjE3sRg&dib_tag=se&keywords=I%2C+Jedi&qid=1737577082&s=digital-text&sprefix=i%2C+jedi%2Cdigital-text%2C202&sr=1-1">Amazon</a>
    },
    {
        title:"Power Of The Jedi Sourcebook",
        author:"Various",
        image:"./books/jediSourcebook.jpg",
        link:<a className="Book-link" href="./files/Power-Of-The-Jedi-Sourcebook.pdf" download>Download</a>
    },
    {
        title:"Star Wars: Jedi Apprentice Series",
        author:"Dave Wolverton, Jude Watson",
        image:"./books/jediApprentice.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Star-APPRENTICE-Complete-Volumes-Special/dp/B01M71NIQX/ref=sr_1_5?crid=3OGG2H1W7P8H8&dib=eyJ2IjoiMSJ9._6W1jErICTYo0orOSK_iU-cNVPCSnw3jl4qVTEEDpJrNFr_sV7rJwYFXyXSFQrY_gtKBoYNj5O4ntYxXCg2wJS6gjgHpRvQkj7I0iTv2GI5K_Qh-PovmunFDLOOIX0nc8EOoO3mlaXhQrRZXiC2VRA.1jcgJSeEGfkyEKGeFpOQ3l1Hi7xChtuzkcMNKjUv_k0&dib_tag=se&keywords=jedi+apprentice+the+rising+force&qid=1737604530&s=digital-text&sprefix=jedi+apprentice+the+rising+force%2Cdigital-text%2C145&sr=1-5">Amazon</a>
    },
    {
        title:"Star Wars: Darth Bane Trilogy",
        author:"Drew Karpyshyn",
        image:"./books/baneTrilogy.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Star-Wars-Essential-Collection-Destruction/dp/1126350125/ref=asc_df_1126350125?mcid=d64e11ac75f735a5adc4971de1f0eab3&hvocijid=12057369911551301443-1126350125-&hvexpln=73&tag=hyprod-20&linkCode=df0&hvadid=721245378154&hvpos=&hvnetw=g&hvrand=12057369911551301443&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9004924&hvtargid=pla-2281435177658&psc=1">Amazon</a>
    },
    {
        title:"The Hero With A Thousand Faces",
        author:"Joseph Campbell",
        image:"./books/heroThousandFaces.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Thousand-Faces-Collected-Joseph-Campbell-ebook/dp/B08MWW2VDL/ref=sr_1_1?crid=B5S1NLAETBDS&dib=eyJ2IjoiMSJ9.BzrMUk6TqdSx8FlrGkX5GKhgSS9VW2R19Y7sgYf1-WEBNpIWUkK5c5bLpI6ci1J-O0H7mB6aB9IY72jNI-FBgIiNCit0C9cf_tFwIhCa0XdgFMBoTGHEipVn1EMDZj2jN0ObovShHNC1Kz1h4mtxIjv9Nbwiw_Rk2Wo2p8tOiq7KkNjR5C8KcBrP4TifTLNThNTrE7mT4wvSb5oCXxbnvjVCPZ0DIf8Ssex3Uv-sJkM.9z4F4lmjFy5ct0CyGhAx0wCCw9sDkCDB98zl0M07tMQ&dib_tag=se&keywords=joseph+campbell+the+hero+with+a+thousand+faces&qid=1737604380&s=digital-text&sprefix=joseph+campbell+the+hero+with+a+thousand+faces%2Cdigital-text%2C284&sr=1-1">Amazon</a>
    }
]

const mentoring =[
    {
        title:"The Elements Of Mentoring",
        author:"W. Brad Johnson & Charles R. Ridley",
        image:"./books/elementsOfMentoring.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Elements-Mentoring-W-Brad-Johnson/dp/1250360110/ref=sr_1_1?crid=1Z48KEW17MTCJ&dib=eyJ2IjoiMSJ9.TZkdcmk6430bwaMZPw4uZNvrleLYJlsyGUcEMDn_0lE.nA27qzCLG6GMjXHXR9pmO0zFn0nGGA-AWjkwNl_cIgk&dib_tag=se&keywords=The+Elements+of+Mentoring+by+W.+Brad+Johnson+and+Charles+R.+Ridley&qid=1737595657&sprefix=the+elements+of+mentoring+by+w.+brad+johnson+and+charles+r.+ridley%2Caps%2C199&sr=8-1">Amazon</a>
    },
    {
        title:"The Mentor's Guide",
        author:"Lois J. Zachary",
        image:"./books/theMentorsGuide.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Mentors-Guide-Facilitating-Effective-Relationships/dp/1119838185/ref=sr_1_1?crid=PQ4GAVDKA1RR&dib=eyJ2IjoiMSJ9.iYKnLJMf__pcsEHtc5EjZo27vlsXxAvXmrCexHTiVX1nfVLk_6nbq71CQE3Eysnc78N5MxeduRpxmRrh0rJExuazk2lvkZS2h3Xup7SsPWh2LwVYNSdB3ni70CDFTh120OWerYj7aKvF8-QsDp2hnw.5RMtVBIeeuPW6vVFzH6AK8BzWb2SNC2ovgRCkXPkBCY&dib_tag=se&keywords=The+Mentor%E2%80%99s+Guide+by+Lois+J.+Zachary&qid=1737595622&sprefix=the+mentor+s+guide+by+lois+j.+zachary%2Caps%2C191&sr=8-1">Amazon</a>
    }
   
]

const spirituality =[
    {
        title:"Buddhist Sutras",
        author:"Various",
        image:"./books/buddhistSutras.jpg",
        link:<a className="Book-link" href="./files/Buddhist-Sutras.pdf" download>Download</a>
    },
    {
        title:"Still The Mind",
        author:"Alan Watts",
        image:"./books/stillTheMind.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Still-Mind-Alan-Watts-ebook/dp/B003CIQ56U/ref=sr_1_1?crid=3ADOG4W7HYSEH&dib=eyJ2IjoiMSJ9.hV_XJOd99hTxHzBccsXQ9MTDPOiBqAUMEdRTUv9FE1kacVXZ3uTg4C4CwhGKodXGGXjeW_KKHolc7qbrFZJybSKOlK04xsEhWaNJ6TE7ijmTH4-xHwPDx4SHBsSE533NNbcXC4jXznKv9cpC55LyhQCqU4KCHk2cxj8MgGo1i1JPL6cDyDDWNiHgzwqG4VSfhPVmiHgMk3pPBFA7QALeVZiyqvISU4GTUhd2VbYNgZD-jN-usWr-DMAGrHprXAdrgylkltYVtz_YQ6oB2Fh-nfmLS-ijDnYgwHn_l-jtzKE.yMNqgL2cP2sIYaMv3OqeKcCiFVN2J3Cz5i14--C5ws0&dib_tag=se&keywords=introduction+to+meditation+alan+watts&qid=1737602285&s=digital-text&sprefix=introduction+to+meditation+alan+watts%2Cdigital-text%2C182&sr=1-1">Amazon</a>
    },
    {
        title:"The Miracle Of Mindfulness",
        author:"Thich Nhat Hanh",
        image:"./books/miracleOfMindfulness.jpg",
        link:<a className="Book-link" href="./files/The-Miracle-Of-Mindfulness.pdf" download>Download</a>
    },
    {
        title:"The Pali Canon",
        author:"Various",
        image:"./books/thePaliCanon.jpg",
        link:<a className="Book-link" href="./files/The-Pali-Canon.pdf" download>Download</a>
    },
    {
        title:"The Power Of Now",
        author:"Eckhart Tolle",
        image:"./books/powerOfNow.jpg",
        link:<a className="Book-link" href="The-Power-Of-Now.pdf" download>Download</a>
    },
    {
        title:"Tibetan Book Of The Dead",
        author:"Karma Lingpa",
        image:"./books/bookOfTheDead.jpg",
        link:<a className="Book-link" href="./files/Tibetan-Book-Of-The-Dead.pdf" download>Download</a>
    }
]

const healingArts =[
    {
        title:"First Aid Reference Guide",
        author:"St. Johns Ambulance",
        image:"./books/firstAid.jpg",
        link:<a className="Book-link" href="./files/First-Aid-Reference-Guide.pdf" download>Download</a>
    },
    {
        title:"Handbook of Medicinal Herbs",
        author:"James A. Duke",
        image:"./books/medicinalHerbs.png",
        link:<a className="Book-link" href="./files/Medicinal-Herbs-Handbook.pdf" download>Download</a>
    },
    {
        title:"Psychological First Aid: Field Operations Guide",
        author:"NCTSN",
        image:"./books/psychologicalFirstAid.png",
        link:<a className="Book-link" href="./files/Psychological-First-Aid.pdf" download>Download</a>
    }
]

const leadership =[
    {
        title:"21 Irrefutable Laws Of Leadership",
        author:"James C. Maxwell",
        image:"./books/irrefutableLaws.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/21-Irrefutable-Laws-Leadership-Follow/dp/1400236169/ref=sr_1_1?crid=1H1MOYM6SIIYS&dib=eyJ2IjoiMSJ9.iYws8iK_QdklC_Awf5lSgE1NX1XrJh_ZFngJNuLADoyWU6poYA-gletNoyWlJHbaiNEm7oQ2O2vNmCSB-pPDOqFij4oES6kTrbSRRq--xeUnWe-EpnwBKBpIhp2BGRbwPp4KWRjqaR8ATznhxl9aP8feL-msqpZAnJQQ5q84cCi8LXEAfSldY-sZPwtUgifsjI-JO73AM6ZSAXZFcFEKqcRlsxYvCcKVs8UNVee9tKA.X91RhAtUSm7wK_yo3PzFG9GdHG1hXVSIv5BSGN9_Wa4&dib_tag=se&keywords=21+Irrefutable+Laws+of+Leadership+by+James+C.+Maxwell&qid=1737595162&sprefix=21+irrefutable+laws+of+leadership+by+james+c.+maxwell+%2Caps%2C159&sr=8-1">Amazon</a>

    },
    {
        title:"Encouraging the Heart",
        author:"Barry Posner & James M. Kouzes",
        image:"./books/encouragingTheHeart.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Encouraging-Heart-Leaders-Rewarding-Recognizing/dp/0787964638/ref=sr_1_1?crid=18VZGHNK6FOSN&dib=eyJ2IjoiMSJ9.VvOefxMQmNaogYKPZDCkAEkxYHyAyOC6ORi0QMiLB5aN6DmQ4gLe0jb5eAAgB5Z1BoESFnAaAAaqHPgUd5Rt8I7-qfmeLewXYpatJa72vDf4OrR5K8EXYUjBz4EXL9phA_n6LZi_8jyhmEEiXQjveUE6Bd3jrYSKExmoXwyscU4UyXNYXdQE1Jpf-IT8hsVS5mBqWno4Z5w3OvwgX8h7uBoYR0GF24CNfOWy6tRuPZs.v9T3t2z5s_AGQ0gNQZZHqVE3SZWCR9YjIUSL5CC9Trg&dib_tag=se&keywords=Encouraging+the+Heart+by+Barry+Posner+and+James+M.+Kouzes&qid=1737595070&sprefix=encouraging+the+heart+by+barry+posner+and+james+m.+kouzes+%2Caps%2C129&sr=8-1">Amazon</a>
    },
    {
        title:"Credibility",
        author:"Barry Posner & James M. Kouzes",
        image:"./books/credibility.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Credibility-Leaders-Gain-People-Demand/dp/0470651717/ref=sr_1_1?crid=29O9Y6KUTLJJ7&dib=eyJ2IjoiMSJ9.-AlAS-pnOV4TGYkOsS91zKgFKKgpMo2tP7LiyN4KIdRuaSAPvs3OyOZM_TGcWcfd0mpfKdIOAE9osdPTgXhq4CMynfgdTRQUjuLLTwfYu6fpBzjEsEfCAhOF3kNeLiH__URwV-ltYPyOpfSFA0jP6ytK2yUQulaphsf4xUyyS_jytS4misywfZLTnKBbZ72uMgl0pcyUT2WxF4Gbreya6dSbE62YXZUZpSPWgc9pytE.fbNJw7_xL_B88YVRTtZ06lcOj369pBEmnqdakIHGnCw&dib_tag=se&keywords=Credibility+by+Barry+Posner+and+James+M.+Kouzes&qid=1737595097&sprefix=credibility+by+barry+posner+and+james+m.+kouzes+%2Caps%2C241&sr=8-1">Amazon</a>
    },
    {
        title:"Start With Why",
        author:"Simon Sinek",
        image:"./books/startWithWhy.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Start-Why-Leaders-Inspire-Everyone/dp/1591846447/ref=sr_1_1?crid=2JDG8PQKZB3YF&dib=eyJ2IjoiMSJ9.4kIDXmyJjS0Xw-QcilAjNbSom7bXu2K9EzrsM1x7rHq3_PDJC58VyWcuC1HmkuRqJR118CxeKLVLGxiaqIelyn1HYws1H77I9VX36VYgEldmASoBs4CpRdRB8QLm0ZFvGIp3wXBWI2dw2Zfqz-20rnvFpmaPxA_0qhQy6VNb5kxuzBwve8eBT3LT1_oDNKW0Wm74mRkIU71zMNcQ5LkfNOIyeLSW23Cs9iYh5ai8U7w.ILItUMPM9yD0fuYncxyBJctaBMCMW6inaWzZoYhlY_0&dib_tag=se&keywords=Start+with+Why+by+Simon+Sinek&qid=1737595204&sprefix=start+with+why+by+simon+sinek%2Caps%2C365&sr=8-1">Amazon</a>
    },
    {
        title:"The Leadership Challenge",
        author:"Barry Posner & James M. Kouzes",
        image:"./books/leadershipChallenge.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Leadership-Challenge-Extraordinary-Things-Organizations/dp/1119736129/ref=sr_1_1?crid=331N1HGDGAU67&dib=eyJ2IjoiMSJ9.jTSmYMBNEE-lOIJ7xZy5SilKabvbgXccwk2zpxPQ_nFQMJdwnwLS_XfTMRkvER7lyn0oZjpucxYMA7ZGAUvLrdHeRktXJ7II5ZioUURuod5iZEbo1DJCbDtjYuE0f-awVGrSB7Q5nVf1wd4V43dBCmmUgz4INWkk1T_8XY-7ELj17NNQBGjioJ_dJyTDKK-lTg-MOJ1EnRuYjN_ahFlAf21ugrv5Rzqpb_ozQTajVg0.al78CQ8YJUNIx5yeIdij1DGiPTSC5bOHQNvu9ZhAugc&dib_tag=se&keywords=The+Leadership+Challenge+by+Barry+Posner+and+James+M.+Kouzes&qid=1737595037&sprefix=%2Caps%2C367&sr=8-1">Amazon</a>
    },
    {
        title:"The 5 Dysfunctons of a Team",
        author:"Patrick Lencioni",
        image:"./books/dysfunctionsOfATeam.jpg",
        link:<a className="Book-link" href="https://www.amazon.com/Five-Dysfunctions-Team-Leadership-Fable/dp/0787960756/ref=sr_1_1?crid=1OAJC1BDANA2C&dib=eyJ2IjoiMSJ9.jqj8x6UXpy6KUAE90cZaYK-d4WgV9ucZ1DLJoy0RCwZ7OvsuAb3hIJfxqHTQMsPp4Hk3lDy7YXSCwcHdzvQ_lYx-AE4ZjBGUWEfEVE0ACJaKxPqzLQqVDztfkNN_YY7kUsXBS1i0Kryu4uaUGDv-d95HXX2EJEZznvsUHOFPlRY.aBfYDauKggK0p7g5S7Ei6S3Bvr5gKA94-SEbkuo7Vm8&dib_tag=se&keywords=The+5+Dysfunctions+of+a+Team+b+Patrick+Lencioni&qid=1737595227&sprefix=the+5+dysfunctions+of+a+team+b+patrick+lencioni%2Caps%2C437&sr=8-1">Amazon</a>
    }
]

let fullLibrary = [...diplomaticArts, ...healingArts, ...jediRealism, ...leadership, ...loreAndMyth, ...mentoring, ...philosophy, ...spirituality, ...warriorship];
const all = fullLibrary.sort((a, b) => a.title.localeCompare(b.title));





export { all, diplomaticArts, healingArts, jediRealism, leadership, loreAndMyth, mentoring, philosophy, spirituality, warriorship };