import Logo from "../images/logo.png";
import Reddit from "../images/reddit.png";
import Discord from "../images/discord.png";
import Facebook from "../images/facebook.png";
import Bluesky from "../images/sky.png";
import Youtube from "../images/youtube.png";

function Community(){
    return(
        <div className="Community-page">
            <div className="Social-butns">
                <a href="https://www.reddit.com/r/RealJediArts/" className="Social-clickable"><div className="Reddit-butn">
                    <img src={Logo} alt="RJA logo" className="Logo-in-butn" />
                    <div className="Call-txt">
                        <p className="Call-to-action">Join Us<br></br>On Reddit</p>
                        <p className="Call-small-txt" style={{color:"rgb(48, 33, 5)"}}>r/RealJediArts</p>
                    </div>
                    <img src={Reddit} alt="Reddit logo" className="Social-in-butn" />
                </div></a>
                <a href="https://discord.gg/Br8KN3uZRS" className="Social-clickable"><div className="Discord-butn">
                    <img src={Logo} alt="RJA logo" className="Logo-in-butn" />
                    <div className="Call-txt">
                        <p className="Call-to-action">Join Us<br></br>On Discord</p>
                        <p className="Call-small-txt" style={{color:"lightblue"}}>Real Jedi Knights</p>
                    </div>
                    <img src={Discord} alt="Discord logo" className="Social-in-butn" />
                </div></a>
                <a href="https://www.facebook.com/groups/realjediarts" className="Social-clickable"><div className="Facebook-butn">
                    <img src={Logo} alt="RJA logo" className="Logo-in-butn" />
                    <div className="Call-txt">
                        <p className="Call-to-action">Join Us<br></br>On Facebook</p>
                        <p className="Call-small-txt" style={{color:"gray"}}>Real Jedi Knights</p>
                    </div>
                    <img src={Facebook} alt="Facebook logo" className="Social-in-butn" />
                </div></a>
                <a href="https://www.youtube.com/@realjediarts" className="Social-clickable"><div className="Youtube-butn">
                    <img src={Logo} alt="RJA logo" className="Logo-in-butn" />
                    <div className="Call-txt">
                        <p className="Call-to-action">Join Us<br></br>On Youtube</p>
                        <p className="Call-small-txt" style={{color:"white"}}>Real Jedi Knights</p>
                    </div>
                    <img src={Youtube} alt="Youtube logo" className="Social-in-butn" />
                </div></a>
                <a href="https://bsky.app/profile/realjediarts.bsky.social" className="Social-clickable"><div className="Bluesky-butn">
                    <img src={Logo} alt="RJA logo" className="Logo-in-butn" />
                    <div className="Call-txt">
                        <p className="Call-to-action" style={{marginLeft:"-10%"}}>Join Us<br></br>On Bluesky</p>
                        <p className="Call-small-txt" style={{color:"gray", fontSize:"1rem"}}>@realjediarts.bsky.social</p>
                    </div>
                    <img src={Bluesky} alt="Bluesky logo" style={{marginLeft:"-15%"}} className="Social-in-butn" />
                </div></a>
            </div>
            
        </div>
    )
}

export default Community;