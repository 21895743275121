const Nav = () => {
    return(
        <nav className="Nav">
            <button className="Nav-butn" onClick={(() => {
                localStorage.setItem("page", "Home");
                window.location.reload();
            })}>
                <h2 className="Nav-titles">Home</h2>
            </button>
            <button className="Nav-butn" onClick={(() => {
                localStorage.setItem("page", "Library");
                window.location.reload();
            })}>
                <h2 className="Nav-titles">Library</h2>
            </button>
            <button className="Nav-butn" onClick={(() => {
                localStorage.setItem("page", "Community");
                window.location.reload();
            })}>
                <h2 className="Nav-titles">Community</h2>
            </button>
            <button className="Nav-butn" onClick={(() => {
                localStorage.setItem("page", "Get Started");
                window.location.reload();
            })}>
                <h2 className="Nav-titles">Get Started</h2>
            </button>
            
        </nav>
    )
}

export default Nav;