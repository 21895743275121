import QuoteGallery from "../components/QuoteGallery";

function Home(){
    return(
        <div className="Home-page">
            <h1 className="Home-title">Welcome to Real Jedi Arts!</h1>
            <p className="Home-txt">
            Real Jedi Arts is a resource for Jedi Realists to learn about Jedi philosophy and the various Jedi arts including psychology, wellness, spirituality, leadership, diplomacy, preparedness, warriorship, critical thinking, culture, science, and the mysteries. We also discuss Star Wars lore, new and old.

We have a Discord server for discussion, and we post to Reddit, Facebook, Youtube, and Bluesky regularly. Find our official pages by clicking on the Community tab above. Come join the discussion! If we are going to make a difference in this world as Jedi, we are going to have to do it together. By sharing our resources and building eachother up, we can each do our best to contribute positively to the world around us. And together, those small contributions can build to one big force for change.
            </p>
            <p className="Home-txt">
            Jedi Realism is a serious pursuit in the same way as the pursuit to live as a Buddhist, a Taoist, or a Stoic. Real Jedi Arts is not a place for roleplay, but rather a gathering place for the serious students of the Jedi Way. If you're just getting started on your journey, make sure to check out the Get Started page. Newcomers and old hands alike are free to make use of our curated Library page, which features free downloads and Amazon links to suggested reading for all your training needs.
            </p>
            <QuoteGallery />
        </div>
    );
}

export default Home;