import Tablet from "../images/jediCodeTablet.png";
import Training from "../images/training.jpg";
import Logo from "../images/logo.png";

function GetStarted(){
    return(
        <div className="GetStarted-page">
            <h1 className="Get-started-title">Begin Your Jedi Journey</h1>
            <p className="Get-started-text" style={{textAlign:"center"}}>
                "A Jedi must have the deepest commitment, the most serious mind."<br></br> - Master Yoda <br></br><br></br>
                "It will be a hard life. One without reward. Without remorse. Without regret.<br></br> A path will be placed before you. The choice is yours alone. Do what you think you cannot do. <br></br>It will be a hard life. But in the end you will find out who you are."<br></br>- Qui-Gon Jinn
            </p>
            <h2 className="Section-title">What is a Real Jedi?</h2>
            <img src={Tablet} alt="Jedi Code in Old Tongue" className="Tablet-pic" />
            <p className="Get-started-text">
            For thousands of years, man has looked to myth for purpose, meaning, and belonging. As far back as we’re able to see in human history, there have been stories of wild adventures and incredible heroes used to entertain and teach valuable wisdom from generation to generation. We can look to the epics of Gilgamesh or Odysseus; to the creation stories of the Bible or of Ancient Greece; to the legends of King Arthur, the Knights of the Roundtable, and the Holy Grail. The figures in these stories fit to powerful archetypes of human ideals, and the places and events are symbolic - even if fictional - of larger ideas found in the world around us and the world within the human psyche. <br></br><br></br>
            In 1977, a new epic saga was created that has gone on to capture the imagination of millions of people around the world - just as the old myths and legends before it. This saga was called Star Wars. And, over the next 40+ years there would be many new movies, books, comics, games, and TV shows exploring the fictional Star Wars universe and the adventures of its heroes and villains. The most central of these heroic factions is a mixture of the sage, paladin, and wizard archetypes found throughout the ages. The details of their lifestyle and philosophy are an eclectic blend of Samurai bushido, Ancient Greek Stoicism, and the Medieval chivalric code. These heroes are called the Jedi.<br></br><br></br>
            Although the stories of Star Wars are mythic in nature, the Jedi represent a certain kind of human ideal - and their impact on their universe evokes for some a calling to rise up and live a life similarly meaningful and altruistically-driven. For those of us called, the Jedi Path lies open for us to walk. We can emulate them in our demeanor and our service to the world around us. We can become them, in a sense, as Jedi of the real world. And that’s precisely what some of us have chosen to do.<br></br><br></br>
            Since 1998, small groups of enthusiasts around the internet have come together to flesh out the Jedi Way as demonstrated in the fiction; and develop training for the willing and ready to become Jedi Knights tailored to the times and places we find ourselves in. It is the mission of real Jedi to achieve personal transformation into a likeness of these Knights of myth, and to take responsibility for the well-being of our environments - our communities, countries, and our world.<br></br><br></br>
            But to do this, we have to undergo training. To follow the light and live by the code are not easy things to accomplish. To answer the calling for help and be the beacon in the storm takes courage and altruism - yes - but it also takes knowledge, discipline, and a strong understanding of the Force.
            </p>
            <h2 className="Section-title">What is Real Jedi Training?</h2>
            <img src={Training} alt="Luke training with Yoda" className="Tablet-pic" />
            <p className="Get-started-text">
            To become Jedi, we must first give up our right to an easier life. We must forgo the instinct to preserve ourselves above all else. Once we answer this call, we take on difficult and thankless responsibilities – as guardians and stewards of Earth. Before all else, we are Jedi. Between ourselves and the greater good, we choose the greater good. Between loved ones and the greater good, we choose the greater good. Our service to this higher ideal comes first and foremost, and all else is secondary. <br></br><br></br>
            If you’re not willing to take on this great duty, you won’t be alone. Most people aren’t. But, Jedi are not most people.<br></br><br></br>

            <h3>Step 1: </h3>
            
            Acclimatize yourself to the Jedi Way. Learn the Jedi Codes. Study the context of their usage. Dissect each word; each phrase. Meditate. Analyze. Memorize.<br></br><br></br>
            Study on, learning about Jedi philosophy. A great place to start is with the Star Wars lore. Movies, TV shows, and especially books. Real Jedi are, obviously, also big Star Wars fans. For us, it’s no different from mythology – symbolic fiction to be picked apart and learned from. <br></br><br></br>
            Begin working on instilling within yourself the core Jedi traits. You can find a list of the most essential ones, along with Star Wars lore recommendations here:<br></br><br></br>
            Learn about the real world influences on the Jedi archetype. Delve deeper. Learn about Stoics and Stoic philosophy, Taoists and Taoist philosophy, Buddhists and Buddhist philosophy. See what seems to agree with the Jedi path, and discard what dogma you cannot use. A great way to start would be to read: <a href="https://www.reddit.com/r/RealJediArts/comments/1i1wvl5/who_are_the_jedi_based_on/" style={{fontSize:"1rem"}} className="Book-link">Who Are The Jedi Based On?</a> We go into philosophy, aesthetics and traditions, and function in society. <br></br><br></br>

            <h3>Step 2: </h3>
            
            Join a real Jedi order. There are a few of them around the internet, and offline as well. Being around other real Jedi will help you to learn and grow more quickly. A good community will support you on your journey, and help you to overcome stumbling blocks along the way. Consider joining our Discord server – <a href="https://discord.gg/Br8KN3uZRS" className="Book-link" style={{fontSize:"1rem"}}>Real Jedi Arts on Discord</a>. We are always around to support and look after new Jedi. A Jedi Order sets up the standards for different levels of learning. It can be daunting to learn on your own – and working together allows Jedi to positively affect the world on a much larger scale than any one of us can do on our own. <br></br><br></br>
            Keep in mind that you will want to choose your home Order carefully. Find out what they teach and how they teach. Get a sense of the atmosphere and culture of the place before delving too deeply into studies. It’s unfortunate, but there are places out there that won’t offer what you need to become a true Jedi. And some, even, where the culture is hostile or otherwise harmful. <br></br><br></br>
            One of the biggest hurdles you will face is finding Jedi that truly live up to what our title stands for. There are many pretenders and not a lot of systems in place to test those pretenders for real merit. Again, it’s like the world of martial arts. Some people want to have a black belt, but they don’t want to do all the work. Some dojos have inadequate standards to test their students for their worthiness of promotion. Indeed, some allow students to pay their way to whatever belt they may want. This makes it hard to trust in the skill and experience that should be inherent in the belt rank. So too, it is with the ranks of Knight and Master in the Jedi community. <br></br><br></br>
            
            <h3>Step 3: </h3>
            
            Find A Mentor. A promising newcomer is exciting to adepts. Not only is it nice to see fresh faces, but the chance to train the next generation is something true Jedi Knights and Masters long to do. We always want more Jedi to help keep the balance and join us in our quest to make the world a better place. <br></br><br></br>
            To become a Jedi Knight, you must first become a padawan. Just as to become a master blacksmith, you must first become an apprentice blacksmith. Time goes on and on, and masters only get older. For the trade to stay fresh and vibrant, the next generation must be empowered to continue on without them – to become masters in their own right. So too, it is with Jedi.<br></br><br></br>
            It’s said when the student is ready, the master will appear. And often, that’s true. Many Knights and Masters will turn away eager students, at least at first. They’ve often been burned – putting much effort and time into a padawan that’s unable or unwilling to match their energy. They’ll want to make sure that you’re different. That you’re ready. And that the two of you are compatible.<br></br><br></br>
            Keep doing good things. Be consistent. Don’t take ranks or titles you know you’re not ready for. Don’t pester or harass. You will find the mentor you’re looking for. And they will find you.<br></br><br></br>
            
            <h3>Step 4: </h3>
            
            Be a Great Padawan. Your job as a padawan is to listen, ask questions, and implement what you’re taught. You will have your time one day to teach and run your own training, but while you’re apprenticed you must defer those jobs to your Master. Complete your assignments to the best of your ability. Go above and beyond with your efforts. Pick your Master’s brain often – ask good questions and you will get good answers. <br></br><br></br>
            
            Over the course of your training, you should be trained in the basics of many diverse skill-sets. Each chosen as essential for a Jedi to learn. These should include the practical skills of crisis response, physical and psychological first aid, communication and conflict resolution, self-defense/combat, wilderness and urban survival; as well as problem solving and critical thinking. You should also become well-versed in a great many areas of knowledge, including science and language, culture and spirituality, psychology, philosophy, and the great mysteries.<br></br><br></br>
            In some cases, these skills – and this knowledge – will be imparted directly by the Master. Especially in areas where the Master has specific expertise. Otherwise, resources are provided for the padawan to learn from which the Master has approved of – and said learning will be guided and overseen. <br></br><br></br>
            Most vitally, padawanship is a time to gain experience in practical application. A Master will suggest or provide opportunities for the padawan to gain direct experience with various aspects of life as a Jedi – including, most notably, quests of service to others. A padawan is not expected to be perfect, but rather to be resilient and well-intended. As their experience grows, so too will their skill in applying their learning.<br></br><br></br>
            As the padawan matures, they will one day come to approach peership with their Master. And when that day comes, it’s time to be pushed out from the nest. It’s time to become independent – out from under the learning tree of the Master. But, first, the padawan must be tested one last time.<br></br><br></br>
            
            <h3>Step 5: </h3>
            
            Take on the Trials. The Jedi trials are a set of five customized trials for the padawan to face as a means of testing their readiness for Knighthood. These trials include the trials of skill, courage, the flesh, the spirit, and insight. <br></br><br></br>
            The Trial of Skill is not just a test of a Jedi’s skill-sets, but rather is a test of their commitment and focus. It will have a time component, a distraction component, and the major skill tested is no one skill itself but rather the overarching skill of self-discipline.<br></br><br></br>
            The Trial of Courage is a test of a Jedi’s bravery and ability to surmount fear – especially the fear of the unknown. It will have a fearful component particular to the Jedi taking the trial, and will often involve a solo-mission of sorts where the Jedi must face the challenge in isolation.<br></br><br></br>
            The Trial of the Flesh is a test of a Jedi’s physical and mental toughness, especially in regard to enduring pain and discomfort. It will have an endurance component which will push the Jedi to consider quitting/throwing in the towel through relentless physical challenges.  <br></br><br></br>
            The Trial of the Spirit is a test of a Jedi’s ability to make peace with themselves. It involves looking deep within oneself and facing one’s inner shadows. It entails a long period of introspection and facing one’s darkness within. How this is achieved will depend greatly upon the Jedi being tested. <br></br><br></br>
            The Trial of Insight is a test of a Jedi’s ability to see through illusion or confusion and find the hidden truths. Often, there are elaborate puzzles and many forms of deception surrounding the trial, challenging the Jedi to use their higher reasoning and intuition to sniff out solutions in situations that are murky and dizzying in complexity.<br></br><br></br>
            There are times where real life events will present such a profound challenge to the Jedi – even before the end of padawanship – that overcoming them may be considered a valid completion of one or more of these five trials. Indeed, for many early Jedi Realists, this was the only way to truly face the Jedi Trials in any true capacity. Often, over the course of years. <br></br><br></br>
            <h3> Step 6:</h3>
            Become Approved for Knighthood. One’s Master, and often the leadership of the Jedi Order, will deliberate over the records of the padawan’s trials. If these authorities agree that the padawan successfully completed all five of their Jedi Trials, they will be graced with the title of Knight. <br></br><br></br>
            At this point, Jedi training is considered to be complete. Of course, as with any training, there will always be room to grow and improve. It is now up to the new Knight to continue their training and keep up with standards, being sure not to regress.<br></br><br></br>
            A Knight often leaves the Order for some time to pursue further training and gain more real-life experience serving as a Jedi. When they return, they may take on padawans of their own to train just as they were trained.<br></br><br></br>
            The path to a Master ranking is beyond the scope of this post, but needless to say it takes a great deal more time and accomplishments attributed to a Knight in order to graduate to the highest rank in the Order. There should be none in the Order who can dispute their worthiness. And that kind of unanimous standing is not easy to achieve.

            </p>
            <h2 className="Section-title">Start Now</h2>
            <img src={Logo} alt="RJA logo" className="Tablet-pic" />
            <p className="Get-started-text">
            If you’re ready to start your Jedi journey, join us on Discord and on Reddit. Every week, you’ll have access to fresh teachings to sharpen your knowledge of the Jedi Way and the various Jedi Arts. On the server, you’ll be able to train together with other real Jedi - guided by Knightshade and Talon, who have a combined 30 years of experience walking the Jedi Path.<br></br><br></br>

            Your journey is your own, and you will walk it your own way. We will seek to tailor your learning in accordance with your needs and circumstances. If you’re ready to start becoming the change you want to see in the world – if you’re ready to answer the call – find our links on the <a href="/community" className="Book-link" style={{fontSize:"1rem"}}>Community</a> page and get started today!<br></br><br></br>

            We look forward to meeting you and working with you. Today, and everyday, may the Force be with you!
            </p>
        </div>
    )
}

export default GetStarted;