import { all, diplomaticArts, healingArts, jediRealism, leadership, loreAndMyth, mentoring, philosophy, spirituality, warriorship } from "../components/BookData";
import React, {useState} from "react";

function Library(){

    const [selection, setSelection] = useState(all);

    const handleChange = (event) => {
        let newVal = event.target.value;
        if(newVal === "All"){
            setSelection(all);
        }
        if(newVal === "Philosophy"){
            setSelection(philosophy)
        }
        if(newVal === "Jedi Realism"){
            setSelection(jediRealism)
        }
        if(newVal === "Lore & Myth"){
            setSelection(loreAndMyth)
        }
        if(newVal === "Diplomatic Arts"){
            setSelection(diplomaticArts)
        }
        if(newVal === "Warriorship"){
            setSelection(warriorship)
        }
        if(newVal === "Spirituality"){
            setSelection(spirituality)
        }
        if(newVal === "Healing Arts"){
            setSelection(healingArts)
        }
        if(newVal === "Mentoring"){
            setSelection(mentoring)
        }
        if(newVal === "Leadership"){
            setSelection(leadership)
        }
    
    
    }

    return(
        <div className="Library-page">
            <select className="Select-category" onChange={handleChange}>
                <option value={selection} disabled></option>
                <option className="Category-option">All</option>
                <option className="Category-option">Philosophy</option>
                <option className="Category-option">Jedi Realism</option>
                <option className="Category-option">Lore & Myth</option>
                <option className="Category-option">Diplomatic Arts</option>
                <option className="Category-option">Warriorship</option>
                <option className="Category-option">Spirituality</option>
                <option className="Category-option">Healing Arts</option>
                <option className="Category-option">Mentoring</option>
                <option className="Category-option">Leadership</option>
            </select>
            <div className="Library-book-div">
                {selection.map((book) => 
                <div className="Book-item">
                    <img src={book.image} alt="Chuangtzu" className="Book-image" />
                    <p className="Book-title">{book.title} by {book.author}</p>
                    {book.link}
                </div>
                )}
            </div>
        </div>
    )
}

export default Library;