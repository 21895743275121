import './App.css';
import Home from "./pages/Home";
import Library from "./pages/Library";
import Community from "./pages/Community";
import GetStarted from "./pages/GetStarted";
import Header from "./components/Header";
import Nav from "./components/Nav";
import React, {useState} from "react";

function App() {

  const [page, setPage] = useState(<Home />)


  window.addEventListener("load", () =>{
    if(localStorage.getItem("page") === "Home"){
      setPage(<Home />)
    }
    if(localStorage.getItem("page") === "Library"){
      setPage(<Library />)
    }
    if(localStorage.getItem("page") === "Community"){
      setPage(<Community />)
    }
    if(localStorage.getItem("page") === "Get Started"){
      setPage(<GetStarted />)
    }
  })

  return (
    <div className="App">
      <Header />
      <Nav />
      {page}
    </div>
  );
}

export default App;
