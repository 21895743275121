import Logo from "../images/logo.png";

const Header = () => {
    return(
        <header className="Header">
            <img src={Logo} alt="RJA Logo" className="RJA-logo" />
        </header>
    )
}

export default Header;